<template>
  <div>
    <div>
      <div class="">
        <van-search
          v-model="value"
          placeholder="请输入搜索关键词"
          input-align="center"
          label="项目名或ID"
          @search="onSearch"
          show-action
        >
          <template #action>
            <div @click="onSearch" class="btn">搜索</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="list">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-cell :title="item.title" v-for="item in list" :key="item.title">
          <van-button
            type="info"
            size="small"
            @click="onclick($event, item.title)"
            >{{ item.names }}</van-button
          >
        </van-cell>
      </van-list>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      //  搜索栏内容
      value: "",
      list: [],
      loading: false,
      finished: false,
      state: false,
    };
  },
  methods: {
    onLoad() {
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      this.finished = true;
    },
    async onSearch() {
      if (this.state) {
        return;
      }
      this.state = true;
      this.list = [];
      let res = await this.$api.fn_user_project_seach(this.value);
      res = res.data;
      if (typeof res == "string") {
        res = res.replace("\\/g", "");
        res = eval("(" + res + ")");
      }
      res = res.data;
      let addition = 0;
      if (this.$api.IsAgent == true) {
        addition =
          (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
        if (isNaN(addition)) {
          addition = 1;
        }
      }
      res.forEach((element) => {
        let name = "收藏项目";
        if (element.project_id !== undefined) {
          name = "已经收藏";
        }

        if (this.$api.IsAgent == true) {
          this.list.push({
            title:
              "项目ID:[" +
              element.id +
              "]  " +
              element.name +
              "----价格:" +
              parseFloat((element.price * addition).toFixed(4)),
            names: name,
          });
        } else {
          this.list.push({
            title:
              "项目ID:[" +
              element.id +
              "]  " +
              element.name +
              "----价格:" +
              element.price,
            names: name,
          });
        }
      });
      this.state = false;
    },
    async onclick(e, index) {
      if (e.target.innerText === "已经收藏") {
        this.$toast("请不要重复收藏");
        return;
      }
      let ret = await this.$api.fn_user_project_push(
        index.substring(index.indexOf("[") + 1, index.indexOf("]"))
      );
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("收藏成功");
        e.target.innerText = "已经收藏";
        e.toElement.disabled = true;
      } else {
        this.$toast(ret.message);
        e.target.innerText = ret.message;
      }
    },
  },
  created() {
    Dialog.alert({
      message:
        '例如 短信模板"【跟谁学】761098（短信验证码)"\n则搜索 括号中间的 "跟谁学" 这三个字,而不是搜应用的名字',
    }).then(() => {
      // on close
    });
  },
};
</script>

<style scoped>
.serch {
  width: 100%;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 99;
}
.btn {
  width: 2rem;
  text-align: center;
  background: #f0eae7;
}
.list {
  /* 50px */
  margin-top: 0px;
}
</style>
